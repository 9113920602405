define("discourse/plugins/discourse-group-tracker/discourse/connectors/group-edit/group-tracker-group-edit", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function update(group, name, value) {
    group.set(name, value);
    return (0, _ajax.ajax)(`/admin/groups/${group.id}/${name}`, {
      type: "PUT",
      data: group.getProperties(name)
    });
  }
  var _default = _exports.default = {
    actions: {
      trackPostsChanged(value) {
        update(this.args.group, "track_posts", value);
      },
      addToNavigationBarChanged(value) {
        update(this.args.group, "add_to_navigation_bar", value);
      },
      trackedPostIconChanged(value) {
        update(this.args.group, "tracked_post_icon", value);
      }
    }
  };
});