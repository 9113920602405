define("discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-nav", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/url", "discourse/plugins/discourse-group-tracker/lib/group-tracker-icon", "@ember/template-factory"], function (_exports, _component, _component2, _object, _computed, _service, _url, _groupTrackerIcon, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="group-tracker-nav">
    {{#if this.groupTrackerPostsExist}}
      <DButton
        class="btn-default group-tracker-jump-prev"
        @action={{this.jumpToPrevTrackedPost}}
        @icon={{this.prevTrackerIcon}}
        @disabled={{this.prevTrackedPostDisabled}}
      >
        {{d-icon "arrow-left"}}
      </DButton>
      <DButton
        class="btn-default group-tracker-jump-next"
        @action={{this.jumpToNextTrackedPost}}
        @icon={{this.nextTrackerIcon}}
        @disabled={{this.nextTrackedPostDisabled}}
      >
        {{d-icon "arrow-right"}}
      </DButton>
    {{/if}}
  </div>
  */
  {
    "id": "CLwqLbwW",
    "block": "[[[10,0],[14,0,\"group-tracker-nav\"],[12],[1,\"\\n\"],[41,[30,0,[\"groupTrackerPostsExist\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"btn-default group-tracker-jump-prev\"]],[[\"@action\",\"@icon\",\"@disabled\"],[[30,0,[\"jumpToPrevTrackedPost\"]],[30,0,[\"prevTrackerIcon\"]],[30,0,[\"prevTrackedPostDisabled\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"arrow-left\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn-default group-tracker-jump-next\"]],[[\"@action\",\"@icon\",\"@disabled\"],[[30,0,[\"jumpToNextTrackedPost\"]],[30,0,[\"nextTrackerIcon\"]],[30,0,[\"nextTrackedPostDisabled\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,2],[\"arrow-right\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[],false,[\"if\",\"d-button\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-nav.hbs",
    "isStrictMode": false
  });
  let GroupTrackerNav = _exports.default = (_dec = (0, _computed.alias)("args.topic.currentPost"), (_class = class GroupTrackerNav extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "site", _descriptor, this);
      _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
      _initializerDefineProperty(this, "postId", _descriptor3, this);
    }
    getPreviousTrackedPost() {
      const topic = this.args.topic;
      const postStream = topic.get("postStream");
      const stream = postStream.get("stream");
      return topic && topic.tracked_posts && topic.tracked_posts.slice().reverse().find(p => {
        return p.post_number < this.postId && stream.includes(postStream.findPostIdForPostNumber(p.post_number));
      });
    }
    getNextTrackedPost() {
      const topic = this.args.topic;
      const postStream = topic.get("postStream");
      const stream = postStream.get("stream");
      return topic && topic.tracked_posts && topic.tracked_posts.find(p => {
        return p.post_number > this.postId && stream.includes(postStream.findPostIdForPostNumber(p.post_number));
      });
    }
    get nextTrackedPostGroup() {
      const nextTrackedPost = this.getNextTrackedPost();
      return nextTrackedPost ? nextTrackedPost.group : null;
    }
    get nextTrackerIcon() {
      return (0, _groupTrackerIcon.default)(this.nextTrackedPostGroup, this.site, this.siteSettings);
    }
    get nextTrackedPostDisabled() {
      return this.nextTrackedPostGroup === null;
    }
    get groupTrackerPostsExist() {
      return this.nextTrackedPostGroup !== null || this.prevTrackedPostGroup !== null;
    }
    get prevTrackedPostGroup() {
      const prevTrackedPost = this.getPreviousTrackedPost();
      return prevTrackedPost ? prevTrackedPost.group : null;
    }
    get prevTrackerIcon() {
      return (0, _groupTrackerIcon.default)(this.prevTrackedPostGroup, this.site, this.siteSettings);
    }
    get prevTrackedPostDisabled() {
      return this.prevTrackedPostGroup === null;
    }
    jumpToNextTrackedPost() {
      const nextTrackedPost = this.getNextTrackedPost();
      if (nextTrackedPost) {
        const url = this.args.topic.url + "/" + nextTrackedPost.post_number;
        _url.default.routeTo(url);
      }
    }
    jumpToPrevTrackedPost() {
      const prevTrackedPost = this.getPreviousTrackedPost();
      if (prevTrackedPost) {
        const url = this.args.topic.url + "/" + prevTrackedPost.post_number;
        _url.default.routeTo(url);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "postId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "jumpToNextTrackedPost", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "jumpToNextTrackedPost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "jumpToPrevTrackedPost", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "jumpToPrevTrackedPost"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GroupTrackerNav);
});