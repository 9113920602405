define("discourse/plugins/discourse-group-tracker/discourse/raw-templates/connectors/topic-list-before-status/group-tracker-topic-status", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        alias3 = container.hooks.helperMissing,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "    <a class=\"tracked-post group-" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.first_tracked_post.group", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 33
          },
          "end": {
            "line": 3,
            "column": 75
          }
        }
      })) + "\" href=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.url", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 83
          },
          "end": {
            "line": 3,
            "column": 104
          }
        }
      })) + "/" + alias2(lookupProperty(helpers, "get").call(alias1, "context.topic.first_tracked_post.post_number", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 105
          },
          "end": {
            "line": 3,
            "column": 153
          }
        }
      })) + "\" title=\"" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias3).call(alias1, "group_tracker.first_group_post", {
        "name": "i18n",
        "hash": {
          "group": "context.topic.first_tracked_post.group"
        },
        "hashTypes": {
          "group": "PathExpression"
        },
        "hashContexts": {
          "group": depth0
        },
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 162
          },
          "end": {
            "line": 3,
            "column": 248
          }
        }
      })) + "\">\n      " + alias2((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias3).call(alias1, "context.topic.firstTrackedPostIcon", {
        "name": "d-icon",
        "hash": {
          "class": "first-tracked-post"
        },
        "hashTypes": {
          "class": "StringLiteral"
        },
        "hashContexts": {
          "class": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 6
          },
          "end": {
            "line": 4,
            "column": 78
          }
        }
      })) + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "context.siteSettings.group_tracker_topic_icon", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(2, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 6
          },
          "end": {
            "line": 7,
            "column": 13
          }
        }
      })) != null ? stack1 : "") + "    </a>\n";
    },
    "2": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "        " + container.escapeExpression((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "context.siteSettings.group_tracker_topic_icon", {
        "name": "d-icon",
        "hash": {
          "class": "context.siteSettings.group_tracker_topic_icon_class"
        },
        "hashTypes": {
          "class": "PathExpression"
        },
        "hashContexts": {
          "class": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 6,
            "column": 8
          },
          "end": {
            "line": 6,
            "column": 122
          }
        }
      })) + "\n";
    },
    "4": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "context.siteSettings.group_tracker_topic_icon", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(5, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 10,
            "column": 4
          },
          "end": {
            "line": 12,
            "column": 11
          }
        }
      })) != null ? stack1 : "";
    },
    "5": function (container, depth0, helpers, partials, data) {
      var lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
      return "      " + container.escapeExpression((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || container.hooks.helperMissing).call(depth0 != null ? depth0 : container.nullContext || {}, "context.siteSettings.group_tracker_topic_icon", {
        "name": "d-icon",
        "hash": {
          "class": "context.siteSettings.group_tracker_topic_icon_class"
        },
        "hashTypes": {
          "class": "PathExpression"
        },
        "hashContexts": {
          "class": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 11,
            "column": 6
          },
          "end": {
            "line": 11,
            "column": 120
          }
        }
      })) + "\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "<span class=\"topic-icon-container\">\n" + ((stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "context.topic.first_tracked_post", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.program(4, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 2,
            "column": 2
          },
          "end": {
            "line": 13,
            "column": 9
          }
        }
      })) != null ? stack1 : "") + "</span>\n";
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/connectors/topic-list-before-status/group-tracker-topic-status", template, {
    core: true
  });
  var _default = _exports.default = template;
});