define("discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-first-post", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/object/computed", "discourse/lib/url", "@ember/template-factory"], function (_exports, _component, _component2, _object, _computed, _url, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @topic.first_tracked_post}}
    <DButton
      class="first-tracked-post"
      @icon="arrow-circle-up"
      @title="group_tracker.first_post"
      @disabled={{this.disabled}}
      @action={{action "jumpToFirstTrackedPost"}}
    />
  {{/if}}
  */
  {
    "id": "Y7KFT+Pb",
    "block": "[[[41,[30,1,[\"first_tracked_post\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"first-tracked-post\"]],[[\"@icon\",\"@title\",\"@disabled\",\"@action\"],[\"arrow-circle-up\",\"group_tracker.first_post\",[30,0,[\"disabled\"]],[28,[37,2],[[30,0],\"jumpToFirstTrackedPost\"],null]]],null],[1,\"\\n\"]],[]],null]],[\"@topic\"],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-group-tracker/discourse/components/group-tracker-first-post.hbs",
    "isStrictMode": false
  });
  let GroupTrackerFirstPost = _exports.default = (_dec = (0, _computed.readOnly)("args.topic.currentPost"), (_class = class GroupTrackerFirstPost extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "postId", _descriptor, this);
    }
    get disabled() {
      if (this.args.topic.first_tracked_post) {
        return this.args.topic.first_tracked_post.post_number >= this.postId;
      }
      return null;
    }
    jumpToFirstTrackedPost() {
      const topic = this.args.topic;
      if (topic.first_tracked_post) {
        _url.default.jumpToPost(topic.first_tracked_post.post_number);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "postId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "jumpToFirstTrackedPost", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "jumpToFirstTrackedPost"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GroupTrackerFirstPost);
});